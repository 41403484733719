import React from 'react'
import Button from '@material-ui/core/Button';
import Rating from '@material-ui/lab/Rating';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import "./Row.css";

function MovieDialog({ movie, isLargeRow }) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div className={`row__poster ${isLargeRow && "row_posterLarge"}`}>
            <img
                key={movie.fields.id}
                className={` ${isLargeRow && "row_posterLarge"}`}
                src={movie.fields.Attachments[0].url}
                alt={movie.fields.Name}
                onClick={handleClickOpen}
            />
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {movie?.fields.Name}
                    <React.Fragment>
                        <Rating name="half-rating-read" defaultValue={(movie.fields['Will Rating'] / 2)} precision={0.5} readOnly />
                    </React.Fragment>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">

                        {movie.fields['Will Review'] ?
                            <React.Fragment>
                                <h3>My Review:</h3>
                                <p>{movie.fields['Will Review']}</p>
                            </React.Fragment>
                            :
                            <React.Fragment></React.Fragment>
                        }
                        <React.Fragment>
                            <h3>Plot Summary:</h3>
                            <p>{movie?.fields['Plot Summary']}</p>
                        </React.Fragment>

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default MovieDialog;
