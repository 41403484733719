import React from 'react';
import Router from './Router'
import "./App.css";

function App() {

  return (
    <Router />
  );
}

export default App