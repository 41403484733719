import React, { useState, useEffect } from "react";
import Airtable from "airtable";
import "./App.css";
import Row from "./Row";
import requests from "./requests";
import ATBanner from "./ATBanner";
import ATRow from "./ATRow";
import ATTopRated from "./ATTopRated";
import ATWorstRated from "./ATWorstRated";

var base = new Airtable({
    apiKey: "keynaSlTq2bKcmmQf",
}).base("appR9TwBqh3ZagaDc");
function Home() {
    const [items, setItems] = useState([]);
    const [movies, setMovies] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    // const [query, setQuery] = useState("");

    useEffect(() => {
        const fetchItems = async () => {
            setIsLoading(true);

            const result = await base("Movies")
                .select({
                    view: "Viewed",
                    pageSize: 100,
                    // filterByFormula: `IF(AND(NOT({View Date} = ''), OR(FIND(LOWER('${query}'),LOWER({Name})))),TRUE())`,
                    sort: [{ field: "View Date", direction: "desc" }],
                })
                .firstPage();

            setItems(result);
            setMovies(result);
            setIsLoading(false);
        };

        fetchItems();
    }, []);
    // console.log(items);
    return isLoading ? (
        <div className="app">Loading...</div>
    ) : (
            <div className="app">
                {/* <Banner /> */}
                {/* <Row
        title="Netflix Originals"
        fetchUrl={requests.fetchNetflixOriginals}
        isLargeRow
      /> */}
                <ATBanner movie={items[Math.floor(Math.random() * items.length - 1)]} />
                <ATRow title="Recently Viewed" movies={movies} isLargeRow />
                <ATTopRated title="Top Rated" movies={movies} isLargeRow />
                <ATWorstRated title="Worst Rated" movies={movies} isLargeRow />
                {/* <ATRow title="Top Rated" movies={movies} isLargeRow isTopRated /> */}
                {/* <ATRow title="Lowest Rated" movies={items} isLargeRow isLowestRated /> */}
                <Row title="Trending Now" fetchUrl={requests.fetchTrending} isLargeRow />
                {/* <Row title="Top Rated" fetchUrl={requests.fetchTopRated} /> */}
                {/* <Row title="Action Movies" fetchUrl={requests.fetchActionMovies} /> */}
                {/* <Row title="Comedy Movies" fetchUrl={requests.fetchComedyMovies} /> */}
                {/* <Row title="Horror Movies" fetchUrl={requests.fetchHorrorMovies} /> */}
                {/* <Row title="Romance Movies" fetchUrl={requests.fetchRomanceMovies} /> */}
                {/* <Row title="Documentaries" fetchUrl={requests.fetchDocumentaries} /> */}
            </div>
        );
}

export default Home;
