import React, { useState, useEffect } from "react";
import Airtable from "airtable";
import MovieBanner from "./Movie/MovieBanner";
var base = new Airtable({
    apiKey: "keynaSlTq2bKcmmQf",
}).base("appR9TwBqh3ZagaDc");

function Movie(props) {
    const [items, setItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchItems = async () => {
            setIsLoading(true);

            const result = await base("Movies")
                .find(props.match.params.atid)

            setItems(result);
            setIsLoading(false);
        };

        fetchItems();
    }, [props.match.params.atid]);
    // console.log(items);
    return isLoading ? (
        <div className="app">Loading...</div>
    ) : (
            <div className="app">
                <MovieBanner movie={items} />
            </div>
        )
}

export default Movie;
