import React from "react";
import "./Banner.css";
import Rating from '@material-ui/lab/Rating';
import { Link } from 'react-router-dom';

function ATBanner({ movie }) {
    function truncate(str, n) {
        return str?.length > n ? str.substr(0, n - 1) + "..." : str;
    }
    // console.log(movie);
    return (
        <header
            className="banner"
            style={{
                backgroundSize: "cover",
                backgroundImage: `url(
                "${movie?.fields.Backdrop?.[0].url || movie?.fields.Attachments[0].url}"
            )`,
                backgroundPosition: "center center",
            }}
        >
            <div className="banner__contents">
                <h1 className="banner__title">
                    {movie?.fields.Name || movie?.name || movie?.original_name}
                </h1>
                <Rating name="half-rating-read" defaultValue={(movie.fields['Will Rating'] / 2)} precision={0.5} readOnly />

                <h1 className="banner__description">
                    {truncate(movie?.fields['Plot Summary'], 150)}
                </h1>
                <div className="banner__buttons">
                    {/* <Link to={location => `/movie/${movie.id}`} className="banner__button">Learn More</Link> */}
                    {/* <button className="banner__button">Play</button>
                    <button className="banner__button">My List</button> */}
                </div>
            </div>
            <div className="banner--fadeBottom"></div>
        </header>
    );
}

export default ATBanner;
