import React from "react";
import "./Row.css";
// import { Link } from 'react-router-dom';
import MovieDialog from './MovieDialog';

function compare(a, b) {
    // Use toUpperCase() to ignore character casing
    const bandA = a.fields['Will Rating'];
    const bandB = b.fields['Will Rating'];

    let comparison = 0;
    if (bandA > bandB) {
        comparison = 1;
    } else if (bandA < bandB) {
        comparison = -1;
    }
    return comparison;
}
function ATWorstRated({ title, movies, isLargeRow, isTopRated }) {

    return (
        <div className="row">
            <h2>{title}</h2>
            <div className="row__posters">
                {movies.sort(compare).map((movie) => (
                    <MovieDialog movie={movie} isLargeRow />
                ))}
            </div>
        </div>
    );
}

export default ATWorstRated;
