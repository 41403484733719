import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Home from './Home'
import Movie from './Movie'
// import Forms from './Forms'
// import Emma from './Emma'
// import EmmaSummary from './Emma/EmmaSummary'
// import EmmaMailing from './Emma/EmmaMailing'
// import MBOSummary from './MBO/MBOSummary'
// import MBOClient from './MBO/Client/MBOClient'

// The Main component renders one of the three provided
// Routes (provided that one matches). Both the /roster
// and /schedule routes will match any pathname that starts
// with /roster or /schedule. The / route will only match
// when the pathname is exactly the string "/"
const Router = () => (
    <main>
        <Switch>
            <Route exact path='/' component={Home} />
            <Route path='/movie/:atid' component={Movie} />
            {/* <Route exact path='/core' component={Home}/>
      <Route exact path='/forms' component={Forms}/>
      <Route exact path='/emma' component={Emma}/>
      <Route path='/emma/:emmaId/mailing/:mailingId' component={EmmaMailing}/>
      <Route path='/emma/:emmaId' component={EmmaSummary}/>
      <Route path='/mbo/:mboId/client/:clientId' component={MBOClient}/>
      <Route path='/mbo/:mboId' component={MBOSummary}/>  */}
        </Switch>
    </main>
)

export default Router