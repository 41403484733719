import React from "react";
import "./Row.css";
// import { Link } from 'react-router-dom';
import MovieDialog from './MovieDialog';

function compare(a, b) {
    // Use toUpperCase() to ignore character casing
    const bandA = a.fields['Will Rating'];
    const bandB = b.fields['Will Rating'];

    let comparison = 0;
    if (bandA > bandB) {
        comparison = 1;
    } else if (bandA < bandB) {
        comparison = -1;
    }
    return comparison * -1;
}
function ATRow({ title, movies, isLargeRow, isTopRated }) {

    return (
        <div className="row">
            <h2>{title}</h2>
            <div className="row__posters">

                {/* {isTopRated ?
                    movies.sort(compare).map((movie) => (
                        // <Link to={location => `/movie/${movie.id}`} className={`row__poster ${isLargeRow && "row_posterLarge"}`}>
                        //     <img
                        //         key={movie.id}
                        //         className={` ${isLargeRow && "row_posterLarge"}`}
                        //         src={movie.fields.Attachments[0].url}
                        //         alt={movie.name}
                        //     />
                        // </Link>
                        <MovieDialog movie={movie} isLargeRow />
                    ))
                    :
                    movies.map((movie) => (
                        // <Link to={location => `/movie/${movie.id}`} className={`row__poster ${isLargeRow && "row_posterLarge"}`}>
                        //     <img
                        //         key={movie.fields.id}
                        //         className={` ${isLargeRow && "row_posterLarge"}`}
                        //         src={movie.fields.Attachments[0].url}
                        //         alt={movie.name}
                        //     />
                        // </Link>
                        <MovieDialog movie={movie} isLargeRow />
                    ))
                } */}
                {movies.map((movie) => (
                    <MovieDialog movie={movie} isLargeRow />
                ))}
            </div>
        </div>
    );
}

export default ATRow;
